import styled from "styled-components";
import logo from "./assets/logo_slices/logo@2x.png";
import backgroundImg from "./assets/底环_slices/底环.png";
import bottomImg from '../src/assets/imgs2.png'
import { useWindowSize } from "react-use";
// import {LogoCard} from './comoments/logoCard/index';
import { LinkModal } from './comoments/linkModal/index'
import { useState } from 'react';

import styles from './home.module.css';
// background: no-repeat center/56% url(${backgroundImg}) #313131;
const Container = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: #313131;

`;

// margin: 46px 251px;
// 
const HeaderWrap = styled.header`
  position:absolute;
  top:23px;
  left:50%;
  transform:translateX(-50%);
  width: 90%;
  height: 53px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index:100;
`;

const LogoWrap = styled.div`
  width: 320px;
  height: 53px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap:nowrap;
  @media screen and (max-width: 720px){
    width: 160px;
  }
`;

const LogoText = styled.h1`
  width: 280px;
  height: 20px;
  font-size: 20px;
  font-family: 'fontspring-extralight';
  font-weight: normal;
  color: #FFFFFF;
  line-height: 21px;
  margin-left:16px;
  @media screen and (max-width: 720px){
    font-size: 16px;
    display: flex;
    align-items: center;
  }
`;

// width: 45px;
// height: 59px;
// display: inline-block;
const Logo = styled.img`
  width: 38px;
  height: 53px;
  @media screen and (max-width: 720px){
    width: 28px;
    height: 42px;
  }
`;

const AppButton = styled.button`
position:relative ;
  width: 171px;
  height: 40px;
  align-items: center;
  background: #000000;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: all 0.3s linear;
  @media screen and (max-width: 720px){
    width: 130px;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.4);
    transition: all 0.3s linear;
  }
`;

const BgImg = styled.img`
  height: 76vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index:0;
  @media screen and (max-width: 720px){
    height: 66vh;
    left:50%;
    transform:translateX(-50%);
  }
`;

const AppText = styled.div`
  display:inline-block;
  width:100%;
  height:100%;
  color:#ffffff;
  font-size:18px;
  line-height:40px;
  font-family: 'fontspring-extralight';
  font-weight:normal;
  @media screen and (max-width: 720px){
    font-size: 16px;
  }
`;

const MainWrap = styled.div`
  width: 516px;
  height: 264px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  @media screen and (max-width: 720px){
    width: 340px;
    left:50%;
    transform:translateX(-45%);
  }
`;

const Title = styled.div`
  display: inline;
  font-size: 50px;
  font-weight: normal;
  color: #ffffff;
  line-height: 60px;
  letter-spacing: 1px;
  font-family: "fontspring-medium";
  position: relative;
  @media screen and (max-width: 720px){
    font-size: 40px;
    line-height: 56px;
    display: flex;
    
  }
`;

const SubTitle = styled.h3`
  width: 320px;
  height: 144px;
  font-size: 50px;
  text-align: right;
  font-family: "fontspring-extralight";
  font-weight: normal;
  color: #ffffff;
  line-height: 60px;
  letter-spacing: 1px;
  position: absolute;
  right:0;
  @media screen and (max-width: 720px){
    font-size: 40px;
    top: 110px;
    right: 23px;
  }
  &::after {
    content: "";
    width: 40px;
    height: 1px;
    background: #FFFFFF;
    position:absolute;
    bottom:39px;
    right:0;
    transform:translateX(60px);
    @media screen and (max-width: 720px){
      width: 96px;  
      bottom: 15px;
      right: 62px;
      height: 2px;
    }
  }
`;
const BottomImg = styled.img`
position: absolute;
bottom: 56px;
  left: 0;
  right: 0;
  margin: auto;
  width: 227px;
  height: 28px;
`
// const Card=styled.div`
//   width:fit-content;
//   height:fit-content ;
//   position:absolute;
//   right:60px;
//   bottom:45px; 


//   @media screen and (max-width: 1200px){
//     left:50%;
//     bottom:45px; 
//     transform:translateX(-50%) ;
//   }
// `
const Homepage = () => {

  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const disVisibility = () => {
    setIsVisible(false);
  };
  const [open, setOpen] = useState(false);
  const { width } = useWindowSize();

  function isShowModal(e) {
    e.stopPropagation();
    setOpen(!open);
  }

  function closeModal(e) {
    e.stopPropagation();
    setOpen(false);
  }
  const btnClick = (event) => {
    event.stopPropagation();
    // setIsVisible(!isVisible);
  };

  return (
    <Container onClick={(e) => closeModal(e)}>
      <BgImg src={backgroundImg} />
      <HeaderWrap>
        <LogoWrap>
          <Logo src={logo}></Logo>
          <LogoText>Star Name Service</LogoText>
        </LogoWrap>
        <AppButton>
          <AppText onClick={(e) => isShowModal(e)} >Launch App</AppText>
          <LinkModal isShowModal={() => setOpen(x => !x)} open={open} />
        </AppButton>
      </HeaderWrap>

      <MainWrap>
        <Title>
          Omnichain <br /> naming for&nbsp;
          {width < 720 && <br />}
          wallets
          <SubTitle>
            websites <br /> and more
          </SubTitle>
        </Title>
      </MainWrap>

      <BottomImg src={bottomImg} />

      <div onClick={disVisibility} className={`${styles.msgBox} ${isVisible ? `${styles.visible}` : `${styles.hidden}`}`}>
        <div className={styles.msgContent}>
          <h2 className={styles.msgTitle}>Urgent Notification:</h2>
          <p>
            Our official X and Discord accounts were misappropriated by a project which is not affiliated with us.
          </p>
          <p>
            Star Protocol and related products continue to operate as usual.
          </p>
          <p>
            To retain eligibility for airdrops, avoid migrating the domain name to other projects.
          </p>
          Please follow our updated Twitter and Discord for the latest info. Thank you for your continued trust and support.

        </div>
        <div className={styles.msgBar} onClick={btnClick} >
          <a href='https://twitter.com/intent/follow?original_referer=https%3A%2F%2Fpublish.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5Eelon&region=follow_link&screen_name=starprotocol_hq' target={'_blank'} rel="noreferrer" className={styles.msgBtn}>Follow X.com</a>
          <a href="https://discord.com/invite/ZDGpWaSJ3u" target={'_blank'} rel="noreferrer" className={styles.msgBtn}>Join Discord</a>
        </div>
      </div>
      {/* <Card>
        <LogoCard/>
      </Card> */}
    </Container>
  );
};

export default Homepage;
